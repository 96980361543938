import React, { useState } from "react";
import './ShoppingCart.css'; // Import the enhanced CSS

// Product component
function Product({ item, addToCart }) {
  return (
    <div className="product-card">
      <h3>{item.name}</h3>
      <p className="product-price">Price: ${item.price.toFixed(2)}</p>
      <button onClick={() => addToCart(item)} className="add-to-cart-btn">
        Add to Cart
      </button>
    </div>
  );
}

// Shopping Cart component
function ShoppingCart({ cartItems, removeFromCart, increaseQty, decreaseQty }) {
  const total = cartItems
    .reduce((acc, item) => acc + item.price * item.quantity, 0)
    .toFixed(2);

  return (
    <div className="shopping-cart">
      <h2>Your Shopping Cart</h2>
      {cartItems.length === 0 ? (
        <p className="empty-cart-message">Your cart is empty</p>
      ) : (
        <>
          <ul>
            {cartItems.map((item) => (
              <li key={item.id} className="cart-item">
                <span className="item-name">{item.name}</span>
                <span className="item-details">
                  {item.quantity} x ${item.price.toFixed(2)}
                </span>
                <div className="quantity-controls">
                  <button onClick={() => decreaseQty(item)}>-</button>
                  <button onClick={() => increaseQty(item)}>+</button>
                </div>
                <button onClick={() => removeFromCart(item)} className="remove-btn">
                  Remove
                </button>
              </li>
            ))}
          </ul>
          <div className="cart-summary">
            <p>
              <span>Total Items:</span>
              <span>{cartItems.length}</span>
            </p>
            <p className="total-price">
              <span>Total Price:</span>
              <span>${total}</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
}

// New Product Form component
function NewProductForm({ addProduct }) {
  const [newProduct, setNewProduct] = useState({ name: "", price: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newProduct.name || newProduct.price <= 0 || isNaN(newProduct.price)) {
      alert("Please enter a valid name and price.");
      return;
    }
    addProduct(newProduct);
    setNewProduct({ name: "", price: "" });
  };

  return (
    <form onSubmit={handleSubmit} className="new-product-form">
      <input
        type="text"
        placeholder="Product Name"
        value={newProduct.name}
        onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
      />
      <input
        type="number"
        placeholder="Price"
        value={newProduct.price}
        required
        min="0.01"
        step="0.01"
        onChange={(e) =>
          setNewProduct({ ...newProduct, price: parseFloat(e.target.value) })
        }
      />
      <button type="submit" className="add-product-btn">
        Add Product
      </button>
    </form>
  );
}

// Main ShoppingCart component (the main export)
function ShoppingCartComponent() {
  const [products, setProducts] = useState([
    { id: 1, name: "Apple", price: 1 },
    { id: 2, name: "Banana", price: 0.5 },
    { id: 3, name: "Cherry", price: 2 },
  ]);
  const [cart, setCart] = useState([]);

  const addToCart = (item) => {
    const updatedCart = [...cart];
    const existingItem = updatedCart.find((i) => i.id === item.id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      updatedCart.push({ ...item, quantity: 1 });
    }
    setCart(updatedCart);
  };

  const removeFromCart = (item) => {
    setCart(cart.filter((i) => i.id !== item.id));
  };

  const increaseQty = (item) => {
    setCart(
      cart.map((i) =>
        i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
      )
    );
  };

  const decreaseQty = (item) => {
    setCart(
      cart.map((i) =>
        i.id === item.id && i.quantity > 1
          ? { ...i, quantity: i.quantity - 1 }
          : i
      )
    );
  };

  const addProduct = (product) => {
    setProducts([...products, { ...product, id: products.length + 1 }]);
  };

  return (
    <div className="shopping-cart-container">
      <h1> React Shopping Cart </h1>

      <NewProductForm addProduct={addProduct} />

      <div className="product-list">
        {products.map((product) => (
          <Product key={product.id} item={product} addToCart={addToCart} />
        ))}
      </div>

      <ShoppingCart
        cartItems={cart}
        removeFromCart={removeFromCart}
        increaseQty={increaseQty}
        decreaseQty={decreaseQty}
      />
    </div>
  );
}

export default ShoppingCartComponent;
