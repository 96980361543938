import React from 'react'
import Button from '@mui/material/Button';
import aim from '../assets/aim.png';
import sci from '../assets/sci.png';
import Linear from '../assets/Linear.png';
import Aframe from '../assets/Aframe.png';
import TensorFlow from '../assets/TensorFlow.png';
import ML from '../assets/ML.jpg';
import micro from '../assets/micro.png';
import prob from '../assets/prob.png';
import py from '../assets/py.png';
import ibm from '../assets/ibm.jpeg';

function Courses() {
  return (
    <div>
    <h1>Self-Study Curriculum</h1>
        <h2>📚Courses and textbooks I'm currently self-studying📚</h2>
    <div className='intro2'>
    <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={prob} width="230px"></img>
          <h1>Introduction to Deep Learning</h1>
          <h2>
          Introduction to Deep Learning is a fundamental course in machine learning. 
          
          </h2>
          {/* <br/> */}
          <Button className='color71' variant="outlined" href="chrome-extension://mhnlakgilnojmhinhkckjpncpbhabphi/pages/pdf/web/viewer.html?file=http%3A%2F%2Ftechinvest.li%2Ftinux%2Fai%2FUnderstandingDeepLearning_24_11_23_C.pdf" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        {/* <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={prob} width="230px"></img>
          <h1>Introduction to Apache Spark</h1>
          <h2>
        Apache Spark is an open-source cluster computing framework developed by the Apache Software Foundation.
          
          </h2>
        
          <Button className='color71' variant="outlined" href="https://spark.apache.org/docs/latest/quick-start.html" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div> */}

        {/* <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={prob} width="230px"></img>
          <h1>Introduction to Deep Learning</h1>
          <h2>
          Introduction to Deep Learning is a fundamental course in machine learning.
          
          </h2>
          
          <Button className='color71' variant="outlined" href="chrome-extension://mhnlakgilnojmhinhkckjpncpbhabphi/pages/pdf/web/viewer.html?file=http%3A%2F%2Ftechinvest.li%2Ftinux%2Fai%2FUnderstandingDeepLearning_24_11_23_C.pdf" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div> */}
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={prob} width="230px"></img>
          <h1>Introduction to GBM ( Gradient Boosting Machine )</h1>
          <h2>
          Gradient Boosting Machines (GBMs) are an effective machine learning model that can help to improve the accuracy of a machine learning model. 
          
          </h2>
          {/* <br/> */}
          <Button className='color71' variant="outlined" href="https://machinelearningmastery.com/gradient-boosting-with-scikit-learn-xgboost-lightgbm-and-catboost/" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>

        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={prob} width="230px"></img>
          <h1>Neural Networks and Deep  Learning</h1>
          <h2>
          Neural Networks and Deep  Learning are  two  of  the  most  popular  machine  learning  algorithms.
          
          </h2>
          {/* <br/> */}
          <Button className='color71' variant="outlined" href="chrome-extension://mhnlakgilnojmhinhkckjpncpbhabphi/pages/pdf/web/viewer.html?file=https%3A%2F%2Fklab.tch.harvard.edu%2Facademia%2Fclasses%2FBAI%2Fpdfs%2Fintro-deep-learning.pdf" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>

        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={prob} width="230px"></img>
          <h1>Introduction to Probability</h1>
          <h2>
          Probability is the branch of mathematics concerning numerical descriptions of how likely an event is to occur, or how likely it is that a proposition is true.
          </h2>
          {/* <br/> */}
          <Button className='color71' variant="outlined" href="https://drive.google.com/file/d/1VmkAAGOYCTORq1wxSQqy255qLJjTNvBI/edit" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>

        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={prob} width="230px"></img>
          <h1>Introduction to Statistics</h1>
          <h2>
          Introduction to  statistics  is  a  branch  of  mathematics  that  deals  with  the  collection,  analysis,  and  interpretation  of  data.  

          </h2>
          {/* <br/> */}
          <Button className='color71' variant="outlined" href="chrome-extension://mhnlakgilnojmhinhkckjpncpbhabphi/pages/pdf/web/viewer.html?file=https%3A%2F%2Fonlinestatbook.com%2FOnline_Statistics_Education.pdf" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
    <div className="port-3">
          
          <img loading="lazy" className="pfp1 color73" alt="pfp" src={ibm} width="240px" />
          <h1>IBM Quantum Learning</h1>
          <h2>
          Learn the basics of quantum computing, and how to use IBM Quantum services and systems to solve real-world problems.

</h2>
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
  
</math>  
          {/* <br/> */}
          <Button className='color73' variant="outlined" href="https://learning.quantum.ibm.com/" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>

        </div>
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color125" alt="pfp" src={sci} width="200px"></img>
          <h1>Scikit-Linear Models</h1>
          <h2>
          Scikit-learn is a free software machine learning library for the Python programming language. 
          </h2>
          <h3>
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
  <mo>+</mo>
  <mi>&#x3B1;</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>w</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math></h3>

          {/* <br/> */}
          <Button className='color125' variant="outlined" href="/AI_Programmer" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>

        </div>
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fjquery-plugins.net%2Fimage%2Fplugin%2Fbrainjs-javascript-library-for-building-neural-networks.png&f=1&nofb=1&ipt=d828cefbcc76406349ea03b4a7f85dc5369253a3e7229434493b0342e239ecdf&ipo=images" width="200px"></img>
          <h1>Brain Js</h1>
          <h2>
          Brain.js is a library of Neural Networks written in JavaScript.
          </h2>

<h3>npm install brain.js</h3>
          {/* <br/> */}
          <Button className='color71' variant="outlined" href="https://brain.js.org/#/getting-started" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color73" alt="pfp" src={Linear} width="185px"></img>
          <h1>Linear Algebra</h1>
          <h2>
          Linear algebra is the branch of mathematics concerning linear equations such as linear functions such as f(x) = 2x + 3. 
          </h2>
          
          {/* <br/> */}
          <Button className='color73' variant="outlined" href="https://www.math.ucdavis.edu/~linear/linear-guest.pdf" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color125" alt="pfp" src={Aframe} width="200px" ></img>
          <h1>A-Frame</h1>
          <h2>
          A-Frame is an open-source web framework for building virtual reality (VR) experiences.
          </h2>
          {/* <br/> */}
          <Button className='color125' variant="outlined" href="https://aframe.io/" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={ML} width="200px" height="185px"></img>
          <h1>Machine Learning</h1>
          <h2>
          Machine learning is a method of data analysis that automates analytical model building. 
          </h2>
          {/* <br/> */}
          <Button className='color71' variant="outlined" href="https://www.geeksforgeeks.org/machine-learning/?ref=footer" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div> 
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color73" alt="pfp" src={TensorFlow} width="200px"></img>
          <h1>TensorFlow</h1>
          <h2>
          TensorFlow is an open-source machine learning library for research and production. 
          </h2>
          {/* <br/> */}
          <Button className='color73' variant="outlined" href="https://www.tensorflow.org/certificate" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>  
       
          <div className="port-3">
          
          <img loading="lazy" className="pfp1 color125" alt="pfp" src={micro} width="230px"></img>
          <h1>Microeconomics</h1>
          <h2>
          Microeconomics is the study of individuals, households and firms' behavior in decision making and allocation of resources.
          </h2>
          {/* <br/> */}
          <Button className='color125' variant="outlined" href="https://www.pfw.edu/dotAsset/142427.pdf" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color71" alt="pfp" src={prob} width="230px"></img>
          <h1>Introduction to Probability</h1>
          <h2>
          Probability is the branch of mathematics concerning numerical descriptions of how likely an event is to occur, or how likely it is that a proposition is true.
          </h2>
          {/* <br/> */}
          <Button className='color71' variant="outlined" href="https://drive.google.com/file/d/1VmkAAGOYCTORq1wxSQqy255qLJjTNvBI/edit" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        {/* <div className="port-3">
          
          <img className="pfp1" alt="pfp" src={aim} width="200px"></img>
          <h2>Universal Robots</h2>
          <h3>
          Universal Robots is a Danish manufacturer of smaller flexible industrial collaborative robot arms, based in Odense, Denmark. The company is known for its "cobots", collaborative robots designed to work safely alongside humans in a shared workspace.
          </h3>
          <h3>
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
  <mo>+</mo>
  <mi>&#x3B1;</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>w</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
</h3>
 
          <Button variant="outlined" href="https://academy.universal-robots.com/free-e-learning/e-series-e-learning/e-series-core-track/" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>

        </div> */}

     
    </div>
    
    </div>
  )
}

export default Courses