// ProjectList.js
import React from 'react';
import './Project.css';

const ProjectList = () => {
    const projects = [
        {
            id: 1,
            title: "Autonomous Vehicle",
            description: "Development of a three-wheeler vehicle designed for inclusivity and accessibility.",
            timeline: "January 2025 - December 2025",
            status: "In Progress",
            technologies: "Arduino, C++, Python",
            image: "https://via.placeholder.com/400x250?text=Autonomous+Vehicle",
            link: "https://example.com/autonomous-vehicle"
        },
        {
            id: 2,
            title: "Environmental Metrics Visualization Tool",
            description: "Tool to visualize environmental metrics like CO2 emissions.",
            timeline: "March 2024 - September 2024",
            status: "Planned",
            technologies: "React, D3.js",
            image: "https://via.placeholder.com/400x250?text=Environmental+Metrics+Tool",
            link: "https://example.com/environmental-metrics"
        },
        {
            id: 3,
            title: "AI-Powered Chatbot",
            description: "Developing a chatbot to assist users in various queries.",
            timeline: "June 2024 - December 2024",
            status: "In Progress",
            technologies: "Node.js, Dialogflow",
            image: "https://via.placeholder.com/400x250?text=AI+Chatbot",
            link: "https://example.com/ai-chatbot"
        },
        {
            id: 4,
            title: "E-commerce Website",
            description: "Creating a full-fledged e-commerce platform with user authentication.",
            timeline: "February 2024 - August 2024",
            status: "Completed",
            technologies: "React, Node.js, MongoDB",
            image: "https://via.placeholder.com/400x250?text=E-commerce+Website",
            link: "https://example.com/ecommerce"
        },
        {
            id: 5,
            title: "Fitness Tracker App",
            description: "Mobile app to track fitness activities and health metrics.",
            timeline: "May 2024 - November 2024",
            status: "In Progress",
            technologies: "Flutter, Firebase",
            image: "https://via.placeholder.com/400x250?text=Fitness+Tracker",
            link: "https://example.com/fitness-tracker"
        },
        {
            id: 6,
            title: "Online Learning Platform",
            description: "Developing a platform for online courses and certifications.",
            timeline: "January 2024 - September 2024",
            status: "Planned",
            technologies: "React, Ruby on Rails",
            image: "https://via.placeholder.com/400x250?text=Online+Learning",
            link: "https://example.com/online-learning"
        },
        {
            id: 7,
            title: "Weather Forecast Application",
            description: "An application providing real-time weather updates.",
            timeline: "April 2024 - June 2024",
            status: "Completed",
            technologies: "React, OpenWeather API",
            image: "https://via.placeholder.com/400x250?text=Weather+App",
            link: "https://example.com/weather-app"
        },
        {
            id: 8,
            title: "Personal Portfolio Website",
            description: "A personal website to showcase my skills and projects.",
            timeline: "July 2023 - August 2023",
            status: "Completed",
            technologies: "HTML, CSS, JavaScript, React",
            image: "https://via.placeholder.com/400x250?text=Portfolio+Website",
            link: "https://example.com/portfolio"
        },
        {
            id: 9,
            title: "Social Media App",
            description: "Developing a social media platform for connecting users.",
            timeline: "September 2024 - January 2025",
            status: "Planned",
            technologies: "React, Firebase",
            image: "https://via.placeholder.com/400x250?text=Social+Media+App",
            link: "https://example.com/social-media"
        },
        {
            id: 10,
            title: "Blog Platform",
            description: "A blogging platform for users to write and share articles.",
            timeline: "November 2024 - February 2025",
            status: "In Progress",
            technologies: "Next.js, MongoDB",
            image: "https://via.placeholder.com/400x250?text=Blog+Platform",
            link: "https://example.com/blog-platform"
        }
    ];

    return (
        <div className="project-list">
            <header>
                <h1>My Portfolio Projects</h1>
                <p>Explore my various projects and their details.</p>
            </header>
            {projects.map(project => (
                <section className="project" key={project.id}>
                    <h2>{project.title}</h2>
                    <img src={project.image} alt={project.title} />
                    <p><strong>Description:</strong> {project.description}</p>
                    <p><strong>Timeline:</strong> {project.timeline}</p>
                    <p><strong>Status:</strong> {project.status}</p>
                    <p><strong>Technologies Used:</strong> {project.technologies}</p>
                    <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">View Project</a>
                </section>
            ))}
        </div>
    );
};

export default ProjectList;
