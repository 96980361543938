import React, { useEffect, useRef } from 'react';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import './Galaxy.css'; // Import CSS styles for the galaxy

function Galaxy() {
    const canvasRef = useRef(null);
    const audio = useRef(new Audio('cosmic.mp3')); // Use ref to manage audio

    useEffect(() => {
        const canvas = canvasRef.current;
        const engine = new BABYLON.Engine(canvas, true);
        let scene;

        const createScene = () => {
            scene = new BABYLON.Scene(engine);
            scene.clearColor = new BABYLON.Color4(0, 0, 0, 1); // Set background color to black

            // Adjust camera parameters for better visibility
            const camera = new BABYLON.ArcRotateCamera("camera", Math.PI / 2, Math.PI / 4, 200, BABYLON.Vector3.Zero(), scene);
            camera.attachControl(canvas, true);
            camera.wheelDeltaPercentage = 0.01;

            // Light source
            const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);
            light.intensity = 0.4; // Increased light intensity

            // Create background stars
            createStarField(scene);

            // Create a parent node for the galaxies to rotate
            const galaxyParent = new BABYLON.TransformNode("galaxyParent", scene);

            // Create different types of galaxies
            createGalaxies(scene, galaxyParent); // Pass the parent node

            // Animation
            animateGalaxies(galaxyParent); // Pass the parent node for rotation

            return scene;
        };

        const createStarField = (scene) => {
            const starMaterial = new BABYLON.StandardMaterial("starMaterial", scene);
            starMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1); // White stars

            for (let i = 0; i < 1000; i++) { // Increase the number of stars
                const star = BABYLON.MeshBuilder.CreateSphere(`star${i}`, { diameter: Math.random() * 0.1 + 0.05 }, scene);
                star.position.x = Math.random() * 500 - 250; // Spread stars over a larger area
                star.position.y = Math.random() * 500 - 250;
                star.position.z = Math.random() * 500 - 250;
                star.material = starMaterial;
            }
        };

        const createGalaxies = (scene, parentNode) => {
            const galaxies = [
                { type: 'Spiral', position: new BABYLON.Vector3(100, 0, 0), color: new BABYLON.Color3(0.2, 0.4, 0.9), arms: 5, radius: 30 },
                { type: 'Elliptical', position: new BABYLON.Vector3(-100, 0, 0), color: new BABYLON.Color3(1, 0.5, 0.3), radius: 40 },
                { type: 'Irregular', position: new BABYLON.Vector3(0, 0, 100), color: new BABYLON.Color3(1, 1, 0), radius: 20 },
            ];

            galaxies.forEach(galaxy => {
                createGalaxy(scene, galaxy, parentNode); // Pass parent node
            });
        };

        const createGalaxy = (scene, galaxy, parentNode) => {
            if (galaxy.type === 'Spiral') {
                for (let i = 0; i < 100; i++) {
                    const angle = i * (Math.PI * 2) / galaxy.arms; // Determine angle for spiral arms
                    const radius = Math.random() * galaxy.radius;
                    const star = BABYLON.MeshBuilder.CreateSphere(`galaxyStar${i}`, { diameter: Math.random() * 0.2 + 0.1 }, scene);
                    star.position.x = galaxy.position.x + radius * Math.cos(angle);
                    star.position.z = galaxy.position.z + radius * Math.sin(angle);
                    star.position.y = Math.random() * 10 - 5; // Add some height variation
                    star.material = new BABYLON.StandardMaterial("starMaterial", scene);
                    star.material.emissiveColor = galaxy.color;

                    // Parent the star to the galaxy parent
                    star.parent = parentNode;
                }
            } else if (galaxy.type === 'Elliptical') {
                const starCount = 200;
                for (let i = 0; i < starCount; i++) {
                    const star = BABYLON.MeshBuilder.CreateSphere(`ellipticalStar${i}`, { diameter: Math.random() * 0.2 + 0.1 }, scene);
                    star.position.x = galaxy.position.x + (Math.random() * galaxy.radius - galaxy.radius / 2);
                    star.position.z = galaxy.position.z + (Math.random() * galaxy.radius - galaxy.radius / 2);
                    star.position.y = Math.random() * 10 - 5; // Add some height variation
                    star.material = new BABYLON.StandardMaterial("starMaterial", scene);
                    star.material.emissiveColor = galaxy.color;

                    // Parent the star to the galaxy parent
                    star.parent = parentNode;
                }
            } else if (galaxy.type === 'Irregular') {
                const starCount = 150;
                for (let i = 0; i < starCount; i++) {
                    const star = BABYLON.MeshBuilder.CreateSphere(`irregularStar${i}`, { diameter: Math.random() * 0.2 + 0.1 }, scene);
                    star.position.x = galaxy.position.x + (Math.random() * galaxy.radius - galaxy.radius / 2);
                    star.position.z = galaxy.position.z + (Math.random() * galaxy.radius - galaxy.radius / 2);
                    star.position.y = Math.random() * 10 - 5; // Add some height variation
                    star.material = new BABYLON.StandardMaterial("starMaterial", scene);
                    star.material.emissiveColor = galaxy.color;

                    // Parent the star to the galaxy parent
                    star.parent = parentNode;
                }
            }

            // Create a supermassive black hole at the center of the galaxy
            const blackHole = BABYLON.MeshBuilder.CreateSphere("blackHole", { diameter: 5 }, scene);
            blackHole.position = galaxy.position;
            const blackHoleMaterial = new BABYLON.StandardMaterial("blackHoleMaterial", scene);
            blackHoleMaterial.emissiveColor = new BABYLON.Color3(0, 0, 0); // Black hole appears dark
            blackHole.material = blackHoleMaterial;

            // Parent the black hole to the galaxy parent
            blackHole.parent = parentNode;

            // Add planets to the galaxy
            createPlanets(scene, galaxy.position, parentNode); // Pass galaxy position
        };

        const createPlanets = (scene, galaxyPosition, parentNode) => {
            const planetColors = [
                new BABYLON.Color3(0.8, 0.3, 0.3), // Red
                new BABYLON.Color3(0.3, 0.8, 0.3), // Green
                new BABYLON.Color3(0.3, 0.3, 0.8), // Blue
                new BABYLON.Color3(0.8, 0.8, 0.3), // Yellow
                new BABYLON.Color3(0.8, 0.3, 0.8), // Purple
            ];

            const planetCount = Math.floor(Math.random() * 5) + 1; // Random number of planets (1-5)

            for (let i = 0; i < planetCount; i++) {
                const planetSize = Math.random() * 5 + 1; // Random size between 1 and 6
                const distance = Math.random() * 50 + 10; // Random distance from galaxy center (10-60)

                const planet = BABYLON.MeshBuilder.CreateSphere(`planet${i}`, { diameter: planetSize }, scene);
                planet.position.x = galaxyPosition.x + distance; // Position the planet at a distance from the galaxy
                planet.position.y = Math.random() * 10 - 5; // Add some height variation
                planet.position.z = galaxyPosition.z;

                // Randomly assign a color from the array
                const planetColor = planetColors[Math.floor(Math.random() * planetColors.length)];
                const planetMaterial = new BABYLON.StandardMaterial("planetMaterial", scene);
                planetMaterial.emissiveColor = planetColor; // Set emissive color
                planet.material = planetMaterial;

                // Parent the planet to the galaxy parent
                planet.parent = parentNode;

                // Optional: Rotate the planet around the galaxy
                let angle = Math.random() * Math.PI * 2; // Random angle
                const speed = Math.random() * 0.02 + 0.01; // Random speed for rotation
                scene.onBeforeRenderObservable.add(() => {
                    angle += speed;
                    planet.position.x = galaxyPosition.x + distance * Math.cos(angle);
                    planet.position.z = galaxyPosition.z + distance * Math.sin(angle);
                });
            }
        };

        const animateGalaxies = (galaxyParent) => {
            scene.registerBeforeRender(() => {
                galaxyParent.rotation.y += 0.005; // Rotate the galaxies for visual effect
            });
        };

        const renderScene = () => {
            engine.runRenderLoop(() => {
                scene.render();
            });
        };

        window.addEventListener("resize", () => {
            engine.resize();
        });

        createScene();
        renderScene();

        return () => {
            engine.dispose(); // Cleanup on component unmount
        };
    }, []);

    const playSound = () => {
        audio.current.play(); // Play the audio file
    };

    return (
        <div>
            <canvas ref={canvasRef} id="renderCanvas"></canvas>
            <button onClick={playSound}>Play Sound</button>
        </div>
    );
}

export default Galaxy;
