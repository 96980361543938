import React from 'react'
import insta from '../assets/insta.png'
import linkedin from '../assets/linkedin.png'
import github from '../assets/github.png'
import ola from "../assets/ola.jpeg";
import o2 from "../assets/o2.jpeg";
import og from "../assets/og.JPG";
import Olayinks from "../assets/Olayinks.jpg";
import ai from "../assets/ai.webp";
import eye from "../assets/eye.png";
import dev1 from "../assets/dev1.png";
import img1 from "../assets/img1.png";
import img3 from "../assets/img3.png";
import rise from "../assets/rise.png";
import rise2 from "../assets/rise2.png";
import rise3 from "../assets/rise3.webp";
import rise4 from "../assets/rise4.webp";
import rise5 from "../assets/rise5.webp";
import rise6 from "../assets/rise6.webp";
import rise7 from "../assets/rise7.png";
import rise8 from "../assets/rise8.webp";
import code from "../assets/code.png";
import ahead3 from "../assets/ahead3.png";
import pawster2 from "../assets/pawster2.png";
import  './Home.css';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import quantum from '../assets/quantum.jpg'
// import qvid from '../assets/qvid.mp4'
import ai_dev from '../assets/ai_dev.png'
import AI_p from '../assets/AI_p.png'
import Olas from '../assets/Olas.jpg'
import abs from '../assets/abs.jpg'
import abs2 from '../assets/abs2.jpg'
import abs3 from '../assets/abs3.jpg'
import abs4 from '../assets/abs4.jpg'
import abs6 from '../assets/abs6.jpg'
import abs7 from '../assets/abs7.jpg'
import op3 from "../assets/op3.png";
import twitter from "../assets/twitter.png";
import transformer from '../assets/transformer.png'
import oyg from '../assets/oyg.jpg'
import odu from '../assets/odu.jpg'
// import ahead3 from "../assets/ahead3.png";
import game from "../assets/game.png";
// import pawster2 from "../assets/pawster2.png";
// import rise from "../assets/rise.png";
import AheadSt2 from "../assets/AheadSt2.png";
import AheadSt from "../assets/AheadSt.png";
import connect from "../assets/connect.gif";
import nft1 from "../assets/nft1.png"
import OlaF1 from "../assets/OlaF1.png";
import OlaFolio from "../assets/OlaFolio.png";
import Blog from "./Blog"
import Courses from './Courses';
import HTCSS from './HTCSS';
import React_Tutorial from './React_Tuorial';
import Tensor from './Tensor';
import Soccer from './Soccer';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Azureai from '../assets/Azureai.jpeg'
import Another from '../assets/Another.png';
import brand from '../assets/brand.jpg';
import Generative from '../assets/Generative.png'
import FaceRecognition from "./FaceRecognition";
import SentimentAnalysis from './SentimentAnalysis';
import IntroAI from './IntroAI';
import Quick from './Quick';
import Merge from './Merge';
import Bubble from './Bubble';
import Insertion from './Insertion';
import Selection from './Selection';
import D3Chart from './D3Chart';
import Galaxy from './Galaxy'


// import Lofi from '../assets/Lofi.mp3';
// import ola1 from '../assets/ola2.mp3';
// import ola5 from '../assets/ola5.mp3';
// import ola2 from '../assets/ola2.mp3';
// import Afro_AI from './Afro_AI';
import Transp from '../assets/Transp.mp4';
// import ai3 from '../assets/ai3.mp3';
import DeepRacer from './DeepRacer';
import Tokenization from './Tokenization';

// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Stack from '@mui/material/Stack';
// import FutureAI from 
// import {Link} from 'react-bootstrap'

function Home() {
  const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  }));
  return (
    <div>
    {/* <br/> */}
      <div className="text-container">
      {/* <img src={brand} className="App-ola color75" alt="logo" loading="lazy" /> */}
      {/* <img src={odu} className="App-ola color75" alt="logo" loading="lazy" /> */}
      <img src={o2} className="App-ola color75" alt="logo" loading="lazy" />
      
      <h2>
      <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
  <mo>+</mo>
  <mi>&#x3B1;</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>w</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
      </h2>
      {/* <DeepRacer /> */}
      <Box sx={{width: '70%', maxWidth: 300 }}>
<div className="fname">
</div>

      
      </Box>
        <h1>Olayinka Fakanbi</h1>
        <h2 className=" ">
        
         Software Engineer
        <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <mrow data-mjx-texclass="ORD">
    <mover>
      <mi>y</mi>
      <mo stretchy="false">^</mo>
    </mover>
  </mrow>
  <mo stretchy="false">(</mo>
  <mi>w</mi>
  <mo>,</mo>
  <mi>x</mi>
  <mo stretchy="false">)</mo>
  <mo>=</mo>
  <msub>
    <mi>w</mi>
    <mn>0</mn>
  </msub>
  <mo>+</mo>
  <msub>
    <mi>w</mi>
    <mn>1</mn>
  </msub>
  <msub>
    <mi>x</mi>
    <mn>1</mn>
  </msub>
  <mo>+</mo>
  <mo>.</mo>
  <mo>.</mo>
  <mo>.</mo>
  <mo>+</mo>
  <msub>
    <mi>w</mi>
    <mi>p</mi>
  </msub>
  <msub>
    <mi>x</mi>
    <mi>p</mi>
  </msub>
</math> </h2>
        <div className="socio">
       
          
        
        </div>
      </div>
      
      {/* <audio controls>
        <source src={ola1} />

        Your browser does not support the audio element.
    </audio> */}
      <div className="socio">
        <a href="https://github.com/Olayinka19" target="_blank"  >
         <img loading="lazy" src={github} width="50px" height="50px" /></a> 
        <a href="https://www.linkedin.com/in/olayinka-fakanbi-2a46a6229/" target="_blank">
        <img loading="lazy" src={linkedin} width="50px" height="50px" /> </a> 
         <a href="https://twitter.com/OlaFolio1" target="_blank">
         <img loading="lazy" src={twitter} width="50px" height="50px" /></a> 
          
        
        </div >
        
        {/* <h1>Sorting Algorithms</h1> */}
        <h1>Prompt-Generated Art Gallery</h1>
        <div className="intro2">
        <div className="port-2">
        <img loading="lazy" src={abs} width="350px" height="450px" />
        </div>
        
        <div className="port-2">
        <img loading="lazy" src={abs2} width="350px" height="450px" />
        </div>
        <div className="port-2">
        <img loading="lazy" src={abs3} width="350px" height="450px" />
        </div>
        <div className="port-2">
        <img loading="lazy" src={abs4} width="350px" height="450px" />
        </div>
        <div className="port-2">
        <img loading="lazy" src={abs6} width="350px" height="450px" />
        </div>
        <div className="port-2">
        <img loading="lazy" src={abs7} width="350px" height="450px" />
        </div>
        </div>
       
        <h1>Sorting Algorithms</h1>
        <div className='intro4'>
       
        
        <Quick />
        <Merge />
        <Bubble />
       
        {/* <D3Chart /> */}
        {/* <Insertion /> */}
        {/* <D3Chart /> */}
        
       
        </div>
        <div className='intro4'>
      {/* <Tokenization /> */}
        </div>

        {/* <Tokenization />  */}
        <h1> AI Certifications</h1>
      <div className="intro2" color75>
      <div className="port-1" >
      <a href="/AzureAI"><img className="pfp1 color76" alt="pfp" href="" src={Azureai} width="235px"></img></a>
        
          <h1>Azure Machine Learning </h1>
          <h2>
          Azure Machine Learning is a cloud-based service for building, training, and deploying machine learning models.
          </h2>
        
          <Button className='color75'  variant="outlined" href="/AzureAI" target="_blank">
          Learn more ☞
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        
      <div className="port-4">
          <a href="/IntroAI"><img loading="lazy" className="pfp1 color71" alt="pfp" src={Another} width="235px"></img></a>
          <h1>Introduction To AI</h1>
          <h2>
          Introduction to Artificial Intelligence (AI) is a course that provides an overview of AI concepts and technologies.
          </h2>
          
          <Button className='color71'  variant="outlined" href="/IntroAI" target="_blank">
          Learn more ☞
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>

        </div>
         
        <div className="port-2">
        <a href="/GenerativeAI"><img loading="lazy" className="pfp1 color73" href="" alt="pfp" src={Generative} width="235px"></img></a>
        
          <h1>Generative AI</h1>
          <h2>
          Generative AI is a type of artificial intelligence that generates new data, such as images, music, and text.
          </h2>
          
          <Button className='color73'  variant="outlined" href="/GenerativeAI" target="_blank">
          Learn more ☞
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        </div>
      
        
     
      {/* <h1>Projects</h1> */}
      {/* <div className="intro2">
      <div className="port-1">
        <img className="pfp1" alt="pfp" src={OlaF1} width="200px"></img>
          <h1>OlaFolio Fruits </h1>
          <h2>
          A simple web application that displays a list of fruits and their health benefits.
          </h2>
         
       
          <Button variant="outlined" href="/OlaFruits" target="_blank">
          ☞Learn More
</Button>
          
        </div>
      <div className="port-4">
          <img loading="lazy" className="pfp1" alt="pfp" src={connect} width="200px"></img>
          <h1>Pursuit Connect</h1>
          <h2>
          Pursuit Connect is an Hackathon Project and a social media platform that connects Pursuit Fellows, Alumni, and Staff. It is available on the web and mobile devices.
          </h2>
      
          <Button variant="outlined" href="https://masonmei.wixsite.com/pursuitconnect/post/faho-blog" target="_blank">
          ☞Visit Website
</Button>
        </div>
         
        <div className="port-2">
        <img loading="lazy" className="pfp1" alt="pfp" src={OlaFolio} width="200px"></img>
          <h1>OlaFolio Electronics</h1>
          <h2>
          OlaFolio Electronics is an E-commerce website that showcases a dynamic and visually appealing platform dedicated to electronics.
          </h2>
          
          
          <Button variant="outlined" href="https://olafolio-electronics.netlify.app/" target="_blank">
          ☞Visit Website
</Button>
        </div>
        </div> */}
        {/* <div className='intro2'>
        <div className='port-2'>
        <video className="pfp1" alt="pfp" src={Transp} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={ai3} />

        Your browser does not support the audio element.
    </audio>
        

        </div>
        <div className='port-2'>
        <video className="pfp1" alt="pfp" src={Transp} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={ola5} />

        Your browser does not support the audio element.
    </audio>
        

        </div>
      
        <div className='port-1'>
        <video className="pfp1" alt="pfp" src={Transp} width="100%" height="350px"  autoplay="false" muted></video>
        <audio controls>
        <source src={ola1} />

        Your browser does not support the audio element.
    </audio>
        

        </div>
        </div> */}
       
        {/* <Afro_AI /> */}
        {/* <Galaxy /> */}
        {/* <Courses /> */}
      <h1>Latest AI Trends and News</h1>
      <p><math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mfrac>
      <mn>1</mn>
      <mrow>
        <mn>2</mn>
        <msub>
          <mi>n</mi>
          <mrow data-mjx-texclass="ORD">
            <mtext>samples</mtext>
          </mrow>
        </msub>
      </mrow>
    </mfrac>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <mi>X</mi>
    <mi>w</mi>
    <mo>&#x2212;</mo>
    <mi>y</mi>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <msubsup>
      <mrow data-mjx-texclass="ORD">
        <mo stretchy="false">|</mo>
      </mrow>
      <mn>2</mn>
      <mn>2</mn>
    </msubsup>
    <mo>+</mo>
    <mi>&#x3B1;</mi>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <mi>w</mi>
    <mrow data-mjx-texclass="ORD">
      <mo stretchy="false">|</mo>
    </mrow>
    <msub>
      <mo stretchy="false">|</mo>
      <mn>1</mn>
    </msub>
  </mrow>
</math></p>
      {/* <h3 className="projects-title">My Projects</h3> */}
      <div className="intro2">
      <div className="port-2">
        <img loading="lazy" className="pfp1 color52" alt="pfp color76" src={transformer} width="190px"></img>
          <h1>The Transformative Power of Transformer AI</h1>
          <h2>Transformer AI, a groundbreaking technology in the field of artificial intelligence, has emerged as a game-changer. 
</h2>
          
          
          <Button className='color76' variant="outlined" href="/Transformer" target="_blank">
          Learn more ☞
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>  
</Button>
        </div>
        <div className="port-1">
        {/* <video className="pfp1" alt="pfp" src={qvid} width="auto" height="auto"></video> */}
        <img loading="lazy" src={quantum} alt="Quantum Computing" className="pfp1 color76 animate__backInDown" width="260px" />
          <h1>Quantum Computing</h1>
          <h2>At the core of quantum computing lies a departure from traditional computing paradigms. Unlike classical computers, which operate using binary bits representing 0s and 1s.</h2>
         
          {/* <br /> */}
          <Button className='color76'  variant="outlined" href="/Quantum" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
 
</Button>
          
        </div>
        <div className="port-2">
        <img loading="lazy" className="pfp1 color69" alt="pfp" src={ai_dev} width="200px"></img>
          <h1>The Impact of AI on Developers</h1>
          <h2>Artificial Intelligence (AI) has revolutionized the way developers approach their craft, ushering in a new era of innovation and efficiency.</h2>
          
          
          <Button className='color69'  variant="outlined" href="/AI_dev" target="_blank">
          Learn more ☞
          <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
</Button>
 
        </div>
        {/* <div className="port-3">
        <img className="pfp1" alt="pfp" src={rise6} width="260px"></img>
          <h3>What's your view on the future of AI?</h3>
          <p>What your opinion and recommedation for beginner? What's the future of artificial intelligence? </p>
          
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div> */}
       
        <div className="port-3">
          
          <img loading="lazy" className="pfp1 color73" alt="pfp" src={AI_p} width="200px"></img>
          <h1>The Rise of AI Programmers</h1>
          <h2>As artificial intelligence (AI) continues to advance, there has been a growing discourse surrounding the role of human programmers.</h2>
          
          <Button className='color73'  variant="outlined" href="/AI_Programmer" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
</Button>
        </div>
        <div className="port-2">
        <img loading="lazy" className="pfp1 color66" alt="pfp" src={op3} width="200px"></img>
          <h1>10 Advanced Open Source Command-Line Tools</h1>
          <h2>Command-line tools remain a staple for developers and power users, offering efficiency and flexibility in managing tasks and workflows.</h2>
          
          
          <Button className='color66'  variant="outlined" href="/Command_line" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
</Button>
        </div>
        <div className="port-2">
        <img loading="lazy" className="pfp1 color73" alt="pfp" src={rise5} width="260px"></img>
          <h1>The Impact of AI on Developers</h1>
          <h2>AI has become a prominent force in the field of software development, leading to questions about the future of software engineers. Many wonder if AI will completely replace developers.</h2>
          
          
          <Button className='color73'  variant="outlined" href="https://dly.to/A3RLX3ZP88O" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
</Button>
        </div>
      
      </div>
      
      <div>
      <div className="intro2">
        <div className="port-1">
        <img loading="lazy" className="pfp1 color75" alt="pfp" src={rise2} width="200px"></img>
          <h1>The Future of Developers: AI Takeover Incoming?</h1>
          <h2>The post discusses the future of developers and questions whether AI will replace real developers.</h2>
          
          <Button className='color75'  variant="outlined" href="/AI_Programmer" target="_blank">
 Learn more ☞
 <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
</Button>
          
        </div>
        {/* <div className="port-2">
        <img className="pfp1" alt="pfp" src={rise5} width="260px"></img>
          <h3>The Impact of AI on Developers</h3>
          <p>AI has become a prominent force in the field of software development, leading to questions about the future of software engineers. Many wonder if AI will completely replace developers.</p>
          
          <br/>
          <Button variant="outlined" href="https://dly.to/A3RLX3ZP88O" target="_blank">
 Learn more ☞
</Button>
        </div> */}
        <div className="port-2">
        <img loading="lazy" className="pfp1 color52" alt="pfp" src={rise3} width="260px"></img>
          <h1>C++ 23 and C++ 26: The Future of the Programming Language</h1>
          <h2>C++ 23, the next major upgrade to the widely-used programming language, has been declared feature-complete.</h2>
          
          {/* <br/> */}
          <Button className='color52'  variant="outlined" href="https://dly.to/r38bbXUrHD0" target="_blank">
 LEARN MORE ☞  <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
</Button>

        </div>
        <div className="port-2">
        <img loading="lazy" className="pfp1 color52" alt="pfp" src={rise3} width="260px"></img>
          <h1>C++ 23 and C++ 26: The Future of the Programming Language</h1>
          <h2>C++ 23, the next major upgrade to the widely-used programming language, has been declared feature-complete.</h2>
          
          {/* <br/> */}
          <Button className='color52'  variant="outlined" href="https://dly.to/r38bbXUrHD0" target="_blank">
 LEARN MORE ☞  <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
  <munder>
    <mo data-mjx-texclass="OP" movablelimits="true">min</mo>
    <mrow data-mjx-texclass="ORD">
      <mi>w</mi>
    </mrow>
  </munder>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <mi>X</mi>
  <mi>w</mi>
  <mo>&#x2212;</mo>
  <mi>y</mi>
  <mrow data-mjx-texclass="ORD">
    <mo stretchy="false">|</mo>
  </mrow>
  <msubsup>
    <mo stretchy="false">|</mo>
    <mn>2</mn>
    <mn>2</mn>
  </msubsup>
</math>
</Button>

        </div>
        
        
        {/* <div className="port-3">
        <img className="pfp1" alt="pfp" src={code} width="200px"></img>
          <h3>Unraveling the Mysteries of Quantum Computing</h3>
          <p>In the vast expanse of technological advancement, there exists a realm that transcends the boundaries of classical computing.</p>
          
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div> */}
        {/* <div className="port-4">
          <h3>Projects</h3>
          <p>Check out my projects on GitHub</p>
          <img className="pfp1" alt="pfp" src={github} width="200px"></img>
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div> */}
{/* 
        <div className="port-4">
          <h3>Projects</h3>
          <p>Check out my projects on GitHub</p>
          <img className="pfp1" alt="pfp" src={github} width="200px"></img>
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div> */}
        {/* <div className="port-1">
        <img className="pfp1" alt="pfp" src={ahead3} width="200px"></img>
          <h3>Ahead-store</h3>
          <p>Welcome to the AHEAD Store! This project showcases a dynamic and visually appealing e-commerce.</p>
         
          <br />
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
          
        </div> */}
        {/* <div className="port-2">
        <img className="pfp1" alt="pfp" src={pawster2} width="200px"></img>
          <h3>Pawster-Project</h3>
          <p>Welcome to Pawster's Paradise! This project presents a vibrant and user-friendly platform dedicated to pet lovers.</p>
          
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div> */}
        {/* <div className="port-3">
        <img className="pfp1" alt="pfp" src={code} width="200px"></img>
          <h3>About Me</h3>
          <p>A Passionate and highly motivated Full Stack Engineer with expertise in web development and database management.</p>
          
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div> */}
    
      {/* <div className="port-1">
        <img className="pfp1" alt="pfp" src={ahead3} width="200px"></img>
          <h3>Ahead-store</h3>
          <p>Welcome to the AHEAD Store! This project showcases a dynamic and visually appealing e-commerce.</p>
         
          <br />
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
          
        </div>
        <div className="port-2">
        <img className="pfp1" alt="pfp" src={pawster2} width="200px"></img>
          <h3>Pawster-Project</h3>
          <p>Welcome to Pawster's Paradise! This project presents a vibrant and user-friendly platform dedicated to pet lovers.</p>
          
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div>
        <div className="port-4">
          <h3>Projects</h3>
          <p>Check out my projects on GitHub</p>
          <img className="pfp1" alt="pfp" src={github} width="200px"></img>
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div>
        <div className="port-3">
        <img className="pfp1" alt="pfp" src={code} width="200px"></img>
          <h3>About Me</h3>
          <p>A Passionate and highly motivated Full Stack Engineer with expertise in web development and database management.</p>
          
          <br/>
          <Button variant="outlined" href="#outlined-buttons">
 Visit Website
</Button>
        </div> */}
        
        {/* <div className="intro">
        <b>Hey!</b>
        <h4>☞I'm Olayinka Fakanbi from 🇳🇬, 'Live in 🗽</h4>
        <h4>☞I love Programming 👨🏽‍💻, Playing Soccer🥅 ⚽️ 🏃‍♂️, attending art galleries 🌇 and Artificial Intelligence🤖</h4>
        <h4>☞Feel Free to get in touch with me</h4>
        <h4>☞Feel Free to get in touch with me</h4>
        <h4>☞Feel Free to get in touch with me</h4>
      </div> */}
      </div>
      {/* <div className="intro">
        <b>Hey!</b>
        <h4>☞I'm Olayinka Fakanbi from 🇳🇬, 'Live in 🗽</h4>
        <h4>☞I love Programming 👨🏽‍💻, Playing Soccer🥅 ⚽️ 🏃‍♂️, attending art galleries 🌇 and Artificial Intelligence🤖</h4>
        <h4>☞Feel Free to get in touch with me</h4>
      </div> */}
      {/* <div className="intro">
        <b>Hey!</b>
        <h4>☞I'm Olayinka Fakanbi from 🇳🇬, 'Live in 🗽</h4>
        <h4>☞I love Programming 👨🏽‍💻, Playing Soccer🥅 ⚽️ 🏃‍♂️, attending art galleries 🌇 and Artificial Intelligence🤖</h4>
        <h4>☞Feel Free to get in touch with me</h4>
      </div> */}
      {/* <div className="intro">
        <b>Hey!</b>
        <h4>☞I'm Olayinka Fakanbi from 🇳🇬, 'Live in 🗽</h4>
        <h4>☞I love Programming 👨🏽‍💻, Playing Soccer🥅 ⚽️ 🏃‍♂️, attending art galleries 🌇 and Artificial Intelligence🤖</h4>
        <h4>☞Feel Free to get in touch with me</h4>
      </div> */}
      
        {/* <div className="">
          <div className="port-1">
            <img className="pfp1 img1" alt="pfp" src={eye}></img>
          </div>
          <div className="port-2">
            <img className="pfp1 img1" alt="pfp" src={img1}></img>
          </div>
          <div className="port-3">
            <img className="pfp1 img1" alt="pfp" src={img3}></img>
          </div>
          <div className="port-4"></div>
        </div> */}
      </div>
        {/* <Blog/> */}
        {/* <Soccer /> */}
    
    </div>
  )
}

export default Home